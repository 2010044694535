import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"
import Img from 'gatsby-image'

export const PodIFrame = styled.iframe`
padding-top: 5rem;
margin-left: 5rem;
width: 100%; 
max-width: 460px; 
overflow: hidden; 
border-radius: 10px; 
background: transparent;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
  `};


  ${MEDIA.PHONE`
margin-left: 0;
padding-top: 3rem;
padding-bottom: 1rem;
width: 97%;
margin-left: 1.5%;
  `};

  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};
`;
