import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout/layout';
import { graphql } from 'gatsby';
import Section from '../components/Section/section'
import { ArticleSection } from '../components/Section/section.css'
import ArticleInfo from '../components/article/articleInfo/articleInfo'
import ArticleStatement from '../components/Statement/articleStatement'
import ArticleBody from '../components/article/articleBody/articleBody'
import { useEffect } from 'react'
import SEO from '../components/seo'
import ShareTab from '../components/shareTab/shareTab'
import Typeform from '../components/typeform/typeform'
import TypeformCTA from '../components/typeform/typeformCTA/typeformCTA'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { PodIFrame } from '../components/pod/pod.css'
import SpotifyPlayer from '../components/pod/spotifyPlayer';
// import PodIcon from '../images/pod-icon.png'
// import { logo } from "../../site-config"


const Pod = ({ data }) => {


  console.log("pod data", data)
  const appleId = data.markdownRemark.frontmatter.applePodId
  const post = data.markdownRemark

  console.log(data)

  return (
    <Layout>
      <SEO
        description={post.frontmatter.description}
        image={
          post.frontmatter.cover !== null &&
            post.frontmatter.cover !== undefined ?
            post.frontmatter.cover.childImageSharp.fluid.src :
            `https://mag.ntheos.com/img/ntheos-pod-cover.png`
        }
        title={`Ntheos Pod Episode ${post.frontmatter.title}`}
      />

      <ArticleStatement >
        {post.frontmatter.title}
      </ArticleStatement>

      <ArticleInfo
        cover={post.frontmatter.cover !== null ?
          post.frontmatter.cover.childImageSharp.fluid : null}
        author={post.frontmatter.author || "The Ntheos Team"}
        date={post.frontmatter.date}
        subtitle={post.frontmatter.description}
      />

      <Section type="article" tags={post.frontmatter.tags} size=''>
        <ArticleSection>

          <PodIFrame
            title="spotify"
            src="https://open.spotify.com/embed-podcast/show/14fJsZukyJsEvC8OuDZpRL"
            width="80%"
            height="152"
            frameBorder="0"
            allowtransparency="true"
            allow="encrypted-media"
          />
          {/* <iframe title="spotify" src="https://open.spotify.com/embed/album/18picfKBpRht7eFTS1XAUd" width="300"
            height="80" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe> */}

          {
            appleId !== null &&
            <PodIFrame
              title={post.frontmatter.title}
              src={`https://embed.podcasts.apple.com/us/podcast/testing-pod/id1565873644?i=${appleId}&amp;itsct=podcast_box_player&amp;itscg=30200&amp;theme=light`}
              height="175px"
              frameBorder="0"
              sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation"
              allow="autoplay *; encrypted-media *;"
            />
          }


          <ShareTab shareInfo={post.frontmatter} />

          <ArticleBody
            contentType='pod'
            opener={post.frontmatter.opener}
            html={{ __html: post.html }}
          />
        </ArticleSection>

      </Section>

    </Layout>
  )
};

Pod.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Pod;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug } }) {
      html
      frontmatter {
        title
        fileSize
        description
        date(formatString: "ddd MMMM Do, YYYY")
        tags
        opener
        author
        applePodId
        cover {
            childImageSharp {
            fluid {
            ...GatsbyImageSharpFluid
          }
          }
        }
      }
    }
  }
`
  ;
